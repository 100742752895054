<template>
  <figure class="image is-16by9">
    <div class="icon-close" @click="$emit('onClickClose')">
      <BIcon icon="close" />
    </div>
    <video
      ref="video"
      class="has-ratio"
      :src="src || 'https://az740894.vo.msecnd.net/golstats-bets/golstats.mp4'"
      poster="/images/video-placeholder.svg"
      @click="onClick"
      @ended="onEnded"
      @pause="onClickPause"
      @play="onClickPlay"
      :autoplay="startPlayingOnLoad"
      controls
    />
  </figure>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { BIcon } from 'buefy/dist/esm/icon';

import { clickVideoFlujoCuentaPausa, clickVideoFlujoCuentaPlay } from '@/utils/analytics';

export default {
  components: {
    BIcon,
  },
  props: {
    listenPause: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      required: false,
    },
  },
  data: function() {
    return {
      startPlayingOnLoad: true,
      playing: false,
    };
  },
  mounted() {
    this.$refs.video.play();
    !this.listenPause && this.$store.commit('SET_PAUSE_VIDEO', true);
  },
  async beforeDestroy() {
    await this.$refs.video.pause();
    this.setVideoSrc(null);
  },
  computed: {
    ...mapGetters(['pauseVideos']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
  },
  watch: {
    pauseVideos(newVal) {
      if (this.listenPause && newVal) {
        this.$refs.video.pause();
        this.$store.commit('SET_PAUSE_VIDEO', false);
      }
    },
  },
  methods: {
    ...mapMutations('general', ['setVideoSrc']),
    updateVideoPlayer() {
      this.playing = !this.playing;
      this.playing ? this.$refs.video.play() : this.$refs.video.pause();
    },
    onClick() {
      this.updateVideoPlayer();
    },
    onEnded() {
      this.$emit('ended');
    },
    onClickPause() {
      clickVideoFlujoCuentaPausa(this.getPremiumAccount, this.getIsGolstats);
      this.$emit('pause');
    },
    onClickPlay() {
      clickVideoFlujoCuentaPlay(this.getPremiumAccount, this.getIsGolstats);
      this.$emit('play');
    },
  },
};
</script>
<style scoped>
video {
  width: 94% !important;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
.icon-close {
  display: flex;
  padding: 2.5px;
  top: 0.5%;
  right: 1.5%;
  background-color: #000000;
  color: #ffffff;
  position: absolute;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
}
</style>
